import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { StaticImage } from "gatsby-plugin-image"
import iconBook from "../images/icon_book.svg"
import iconWebsite from "../images/icon_website.svg"
import iconVideo from "../images/icon_video.svg"
import iconQuestion from "../images/icon_question.svg"

const Resources = ({ data, location }) => {
  const intl = useIntl();
  const { title, resources } = data.site.siteMetadata;

  return (
    <Layout location={location} siteTitle={title}>
      <Seo title={intl.formatMessage({id: "navlinks.resources"})} lang={intl.locale} />
      <section>
        <div className="container-hero">
          <div className="text-hero">
            <h1 className="heading-hero">
              <FormattedMessage id="resources.headingHero" />
            </h1>
            <h2 className="heading-hero">
              <FormattedMessage id="resources.headingHero2" />
            </h2>
          </div>
          <StaticImage
            className="cover-slider"
            src="../images/syed-ahmad-unsplash.jpg"
            alt=""
            placeholder="blurred"
          />
        </div>
      </section>
      <section className="resources body">
        <div className="container">
          <div className="section-intro">
            <h3><FormattedMessage id="resources.headingIntro" /></h3>
            <p><FormattedMessage id="resources.paragraphIntro" /></p>
          </div>
          <h5><FormattedMessage id="resources.useful" /></h5>
        </div>
        <div className="collection-list-wrapper">
          <div role="list" className="collection-list">
            {resources.filter(r => r.language === intl.locale)
              .map(r => r.links.map((l, i) => {
                const getIconFor = (resourceType) => {
                  switch (resourceType) {
                    case "reading":
                      return iconBook;
                    case "website":
                      return iconWebsite;
                    case "video":
                      return iconVideo;
                    default:
                      return iconQuestion;
                  }
                }
                const getIndexFor = (i) => r.links.length - i;
                return <div role="listitem" className="collection-item">
                  <a className="link-block" href={l.link} target="_blank" rel="noreferrer">
                    <div className="div-block resource">
                      <div>
                        <img className="resource-type" src={getIconFor(l.type)}
                             alt={intl.formatMessage({ id: `resources.${l.type}` })} />
                        <div>
                          <h6><FormattedMessage id={`resources.title${getIndexFor(i)}`}/></h6>
                          <div className="text-block"><FormattedMessage id={`resources.text${getIndexFor(i)}`}/></div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              })
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Resources

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        resources {
          language
          links {
            type
            link
          }
        }   
      }
    }

  }
`
